import { configureStore} from '@reduxjs/toolkit';
import { useDispatch } from "react-redux"
import { Auth } from './Auth'
import { Template } from './Template'
import { Emails } from './Emails'
import {Loads} from './Loads'
export const store = configureStore({
    reducer: {
        Auth,
        Template,
        Emails,
        Loads
    },
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>