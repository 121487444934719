import React, {useEffect, useState} from 'react'
import {TableLoads} from "../TableLoads/TableLoads";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../app/store";
import {GetOpenedLoads, GetSavedLoads} from "../../app/Loads";

export const Loads = () => {
    const [ buttons , setbuttons ] = useState(false)
    const { SavedLoads,OpenedLoads} = useSelector((state:RootState)=>state.Loads)
    const {email} = useSelector((state:RootState)=>state.Auth)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(GetOpenedLoads(email))
        dispatch(GetSavedLoads(email))
    }, []);
    return (
        <div className='m-10 flex flex-col gap-5 text-[#444]'>
            <div className='flex flex-col gap-2'>
                <div className='font-bold text-[23px]'>
                    Loads
                </div>
                <div className='flex gap-3'>
                    <button onClick={()=>setbuttons(!buttons)} className={buttons?'bg-[#f2f2f2] p-3 rounded ':'bg-gray-300 p-3 rounded '}>
                        Saved loads
                    </button>
                    <button onClick={()=>setbuttons(!buttons)} className={buttons?'bg-gray-300 p-3 rounded ':'bg-[#f2f2f2] p-3 rounded '}>
                        Opened loads
                    </button>
                </div>
            </div>
            <TableLoads data={buttons?OpenedLoads:SavedLoads}/>
        </div>
    )
}
