import React from 'react'
import {ColumnsType} from "antd/es/table";
import { Space, Table} from "antd";
import {useAppDispatch} from "../../app/store";
import {DeleteEmails} from "../../app/Emails";

interface DataType{
    id:number
    name:string,
    email_send:number,
    status:boolean,
    action:any
}
export const TableEmails:React.FC<{data:Array<any>}> = ({data}) => {
    const dispatch = useAppDispatch()
    const columns :ColumnsType<DataType> = [
        {
            title:'Name',
            dataIndex:'name',
            key:'name',
            width:'30%',
        },
        {
            title:'Email Send',
            dataIndex:'email_send',
            key:'email_send',
            width:'20%',
        },
        {
            title:'Status',
            key:'status',
            width:'20%',
            render:(_,redord)=>redord.status ?(<div className='text-green-500'>Verify</div>):(<div className="text-gray-400">unverified</div>)
        },
        {
            title:'Action',
            width:'15%',
            key:'action',
            render: (_,record) =>(
                <Space size="middle">
                    {record.id !==0?<div onClick={()=>dispatch(DeleteEmails(record.id))} className='text-red-600 cursor-pointer hover:text-red-900 '>Delete</div>:<></>}
                </Space>
            )
        },
    ]


    return (
        <>
            <Table className='w-[980px]' columns={columns} dataSource={data}/>
        </>
    )
}
