import React from 'react';
import { useSelector } from 'react-redux';
import {RootState} from "../../app/store";
import { Navigate } from "react-router-dom"
const UserAuthWrapper:React.FC<{children:React.ReactNode}> = ({children}) => {
    const user = useSelector((state:RootState) => state.Auth); // Переконайтеся, що у вас є такий ключ у стейті Redux

    const isAuthenticated = user.email && user.id && user.verified_email;


    if (isAuthenticated) {
        return <>
            {children}
        </>
    } else {
        return <>
            <Navigate to={'/template/sign'}/>
        </>
    }
};

export default UserAuthWrapper;