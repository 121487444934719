import axios from "axios";

const base = axios.create({
    baseURL:"https://master-dispatch.com/api"
})

export class EmailsAPI {
    async Get(email:string) : Promise<Array<{name:string,verify:boolean}>>{
        const res = await base.get("").then(u=>u.data)
        return res
    }
    async Create(newEmails:any) {
        const res = await base.post(`/email_services/add_email/?user_email=${newEmails.mainEmail}`,{status_name:newEmails.status_name,name:newEmails.name,email:newEmails.email,service_name:newEmails.service_name}).then(u =>u.data)
        return res
    }
    async Delete(id:number){
        const res = await base.delete(`/email_services/delete_email/?email_id=${id}`)
        return res
    }
}