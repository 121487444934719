import React from 'react'
import {TableTemplate} from "../TableTemplate/TableTemplate";
import {NavLink} from "react-router-dom";


export const Template = () => {

    return (
        <div className='m-10 flex flex-col gap-5 text-[#444]'>
            <div className='flex flex-col gap-2'>
                <div className='font-bold text-[23px]'>
                    Template
                </div>
                <div className='max-w-[870px]'>
                    Within this segment, you have the capability to create personalized email templates designed for dispatching to brokers. These templates are associated with the extension.
                </div>
                <NavLink to={'/create-template'} className=' flex justify-center items-center bg-indigo-500 hover:bg-sky-400  p-1 text-white font-semibold rounded max-w-[260px]'>
                    Add template
                </NavLink>
            </div>
            <TableTemplate/>
        </div>
    )
}
