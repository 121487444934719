import React, {useEffect, useState} from 'react'
import { useGoogleLogin,} from "@react-oauth/google";
import {message} from "antd";
import axios from "axios";
import {RootState, useAppDispatch} from "../../app/store";
import {SetUserData, SignAc} from "../../app/Auth";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const Sign = () => {
    const dispatch = useAppDispatch()
    const { email } = useSelector((state:RootState)=>state.Auth)
    const [messageApi] = message.useMessage()
    const [ user, setUser ] = useState<any>([]);
    const [ , setProfile ] = useState<any>([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse:any) => {
            setUser(codeResponse)

        },
        onError: (error) => console.error('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res:any) => {
                        messageApi.success("Success")
                        setProfile(res.data);
                        dispatch(SetUserData({name:res.data.name,email:res.data.email,id:res.data.id,verified_email:res.data.verified_email,picture:res.data.picture}))
                        dispatch(SignAc({access_token:user.access_token,name:res.data.name,email:res.data.email,refresh_token:''}))
                    })
                    .catch((err:any) => {
                        messageApi.error("Error")
                    });
            }
        },
        [ user,dispatch,messageApi ]
    );

    if (email){
        return <Navigate to={`/template/extension/${email}`}/>
    }else {
        return (
            <div className='flex justify-center items-center w-[55vw] h-96'>
                <button onClick={()=>login()} className='font-semibold w-[190px] h-[45px] bg-gradient-to-br from-indigo-500 to-sky-400 text-white rounded text-lg'>
                    Sign with Google
                </button>
            </div>
        )
    }
}
