import React from 'react'

export const Extension = () => {
    return (
        <section className='flex flex-col w-[760px] gap-4 mx-48'>
            <h1 className='text-3xl'> Welcome to MasterDispatch </h1>
            <h3 className='text-xl'>
                It is a plugin that simplifies communication between carriers and brokers by allowing carriers to send emails with one click from loadboard on DAT platform.
            </h3>
            <span className="text-[#333] ">You can connect to the extension as many Google accounts as you want, while creating a large number of templates in each of them.</span>
            <span className="text-[#333] ">It also has features to help you use the DAT platform more conveniently, such as: quick email send, filters, RPM calculator, Google Map. Click on "Features" in the extension to start using these functions.</span>
        </section>
    )
}
