import React from 'react'
import { Editor } from "@tinymce/tinymce-react"
import {useFormik} from "formik";
import {RootState, useAppDispatch} from "../../app/store";
import {CreateTemplate} from "../../app/Template";
import {useSelector} from "react-redux";
interface FormikInitialValues{
    name:string,
    subject:string,
    template:any
}
export const ItemTemplate = () => {
    const dispatch = useAppDispatch()
    const {email} =useSelector((u:RootState)=>u.Auth)
    const formik = useFormik<FormikInitialValues>({
        initialValues:{
            subject:'',
            name:'',
            template:''
        },
        onSubmit:(values)=>{
            console.log('click')
            dispatch(CreateTemplate({template:values.template,name:values.name,subject:values.subject,email:email}))
        }
    })

    return (
        <form onSubmit={formik.handleSubmit} className='m-10 flex flex-col gap-5 text-[#444]'>
            <div className='flex flex-col'>
                <div>
                    Template Name
                </div>
                <input onChange={formik.handleChange} name='name' type="text" className='outline-none p-3 ' placeholder='Template Name'/>
            </div>
            <div className='flex flex-col'>
                <div>
                    Subject
                </div>
                <input onChange={formik.handleChange}  name='subject' type="text" className='outline-none p-3 ' placeholder='Subject'/>
            </div>
            <div className='flex flex-col gap-3'>
                <div>
                    Body
                </div>
                <Editor
                    initialValue=""
                    onChange={(e:any)=>formik.setFieldValue("template",e.lastLevel.content)}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                            'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </div>
            <div className='flex gap-5 justify-end'>
                <button className='bg-gradient-to-t from-indigo-500 to-sky-400 text-white h-[45px] w-[160px] rounded'>
                    Cansel
                </button>
                <button onClick={(e:any)=>formik.handleSubmit(e)} className='bg-gradient-to-b from-indigo-500 to-sky-400 text-white h-[45px] w-[160px] rounded'>
                    Save
                </button>
            </div>
        </form>
    )
}
