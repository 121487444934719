import {createAction, createAsyncThunk, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {AuthAPI} from "../API/AuthAPI";

interface InitialStateType{
    id:number |string,
    name:string,
    email:string,
    verified_email:boolean
    picture:string
}

interface UserData{
    id:number |string,
    name:string,
    email:string,
    verified_email:boolean
    picture:string
}

const InitialState :InitialStateType={
    email:'',
    name:'',
    id:0,
    picture:'',
    verified_email:false,
}

export const SignAc = createAsyncThunk<unknown,{access_token:string,email:string,name:string,refresh_token:''}>("SIGN-Ac-AUTH",async (vales)=>{
    const data = await new AuthAPI().Sign(vales)
    localStorage.setItem('token',vales.access_token)
    return data

})
export const Refresh = createAsyncThunk<unknown,string>("REFRESH-AUTH",async (refresh_token,{dispatch})=>{
    return  await new AuthAPI().Refresh(refresh_token)

})
export const Sign_Up = createAsyncThunk("SIGN-UP-AUTH",async (values)=>{
    const data = await new AuthAPI().SignUp()
    return data
})
export const SetUserData = createAction<UserData>("SET-USER-DATA")
const SetRefreshToken = createAction<UserData>("SET-REFRESH-ACTION")
export const Auth = createReducer(InitialState,{
    [SetRefreshToken.type]:(state,action)=>{

    },
    [SetUserData.type]:(state,action:PayloadAction<UserData>)=>{
        localStorage.setItem('email',action.payload.email)

        return{
            ...state,
            verified_email:action.payload.verified_email,
            picture:action.payload.picture,
            name:action.payload.name,
            id:action.payload.id,
            email:action.payload.email
        }
    }
})