import axios from "axios"

const base = axios.create({
    baseURL:"https://master-dispatch.com/api"
})

export class AuthAPI {
    async Sign(data:any){
        const res = await base.post("/sign-up",data).then(response => response.data)

        return res
    }
    async SignUp (){
        const res = await base.post("/sign-up").then(u=>u.data)
        return res
    }
    async AuthIn(){

    }
    async Auth (){

    }
    async Refresh (refresh_token:string){
        const res = await base.get(`/refresh/?refresh_token=${refresh_token}`).then(u=>u.data)
        return res
    }
}