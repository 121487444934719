import axios from "axios";

const base = axios.create({
    baseURL:"https://master-dispatch.com/"
})
export class LoadsAPi {
    async GetSaved(email:string){
        const res = await base.get(`/api/loads/saved/get/email=${email}`).then(u=>u.data)
        return res
    }
    async GetOpened(email:string){
        const res =await base.get(`/api/loads/opened/get/email=${email}`).then(u=>u.data)
        return res
    }
}