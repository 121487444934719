import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import {Provider} from "react-redux";
import {store} from "./app/store";
import {GoogleOAuthProvider} from '@react-oauth/google'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <GoogleOAuthProvider clientId='1021481117853-p9k44cesbhnqh61jspa22dm775ljjjrn.apps.googleusercontent.com'>
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <App/>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);

