import {createAction, createAsyncThunk, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {EmailsAPI} from "../API/EmailsAPI";

type Emails = Array<{name:string,verify:boolean}>
interface InitialStateType{
    emails:Emails
}
const InitialState:InitialStateType={
    emails:[]
}

export const GetEmails = createAsyncThunk<unknown,string>("GET-EMAILS",async (email,{dispatch})=>{
    const res = await new EmailsAPI().Get(email)
    dispatch(SetEmails(res))
})
export  const CreateEmails = createAsyncThunk<unknown,any>("CREATE-EMAILS",async (newEmail,{dispatch})=>{
    const res = await new EmailsAPI().Create(newEmail)
    dispatch(GetEmails(res))
})

const SetEmails = createAction<Emails>("SET-EMAILS")

export const DeleteEmails= createAsyncThunk<unknown,number>("DELETE-EMAILS",async (id)=>{
    return await new EmailsAPI().Delete(id)
})
export const Emails = createReducer(InitialState,{
    [SetEmails.type]:(state,action:PayloadAction<Emails>)=>{
        return{
            ...state,
            emails:action.payload
        }
    }
})