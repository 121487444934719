import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import logo from "../../acces/logo.png"
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {googleLogout} from "@react-oauth/google";
export const Header = () => {
    const [Open,setOpen]= useState<boolean>(false)
    const {email,picture,name} = useSelector((state:RootState)=>state.Auth)
    const logOut = () => {
        googleLogout();
    };

    return (
        <div className="bg-white w-screen h-20 flex justify-between px-20 text-[#222] ">
            <NavLink to={'/template/'} className='flex gap-3 items-center font-semibold text-[23px] text-[#333]'>
                <img alt='' className='h-[65px] w-[65px]' src={logo}/>
                DisptachWizard
            </NavLink>
                <div onClick={()=>setOpen(!Open)} className='flex items-center gap-3'>
                    <div className="flex flex-col gap-1 text-[#555] cursor-pointer">
                        <div className='text-[21px] font-bold'>
                            {email}
                        </div>
                        <div className='text-[17px] font-semibold'>
                            {name}
                        </div>
                    </div>
                    <div className='flex items-center gap-1 cursor-pointer'>
                        <div className="w-[50px] h-[50px] rounded-full bg-gradient-to-br from-indigo-500 via-violet-300 to-sky-400">
                            {
                                picture && <img className='w-[50px] h-[50px] rounded-full' src={picture} alt="logo user"/>
                            }
                        </div>
                        <button className="text-[#333] font-bold text-[19px] rotate-[270deg]">
                            &gt;
                        </button>
                    </div>
                    {
                        Open && <div className='absolute top-[96px] bg-white p-3 w-[320px] drop-shadow rounded'>
                            <button onClick={()=>logOut()} className='font-semibold text-red-500 text-[19px]'>
                                Log out
                            </button>
                        </div>
                    }
                </div>
        </div>
    )
}
