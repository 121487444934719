import React, {useState} from 'react'
import {Slider, Switch} from "antd";

export const Billing = () => {
    const [isYearly,SetYearly] = useState<boolean>(false)
    const [Amount,setAmount] =useState<number>(4)
    return (
        <div className='m-10 flex flex-col gap-5 text-[#444]'>
            <div className='xl:w-[1180px] flex flex-col items-center'>
                <div className='text-[26px] my-6 '>
                    Cost-effective pricing.
                </div>
                <Switch onChange={(e:boolean)=>SetYearly(e)} className='w-[60px] my-10'/>
                {
                    isYearly ? <div className='flex justify-evenly flex-wrap gap-14 lg:gap-3 xl:flex-nowrap '>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Basic
                            </div>
                            <div className='flex md:h-[130px]'>

                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>$12</div>/per month <br/> billed at $144/yr
                                </div>
                            </div>
                            <a  href="/" className=' bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited emails
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    1 User Account
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    RPM calculator
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Gmail, Outlook, SMTP
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    1 Email Template
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Customer Support
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Pro
                            </div>
                            <div className='flex md:h-[130px]'>
                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>$28</div>/per month <br/> billed at $336/yr
                                </div>
                            </div>
                            <a  href="/" className=' bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Everything in Starter, plus:
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited user accounts
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited Emails
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Load Search Filters
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Google Maps
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Multiple email address
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    DAT, Truckstop, DAT One, Truckersedge
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Enterprice
                            </div>
                            <div className='flex items-center flex-col '>
                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>${10*Amount}</div>/per month <br/> billed at ${10*Amount*12}/yr
                                </div>
                                <Slider onChange={(e:number)=>setAmount(e)} min={4} max={20} className='w-[120px]'/>
                                <div>
                                    User Account:{Amount}
                                </div>
                            </div>
                            <a  href="/" className='bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Everything in Pro, plus:
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>From 4 to Unlimited Users</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Multiple email addresses</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Google Maps with Tolls &amp; Load Info</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Multiple Sending Addresses</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Email Signature</span>
                                </li>
                            </ul>
                        </div>
                    </div> : <div className='flex justify-evenly flex-wrap gap-14 lg:gap-3 xl:flex-nowrap '>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Basic
                            </div>
                            <div className='flex md:h-[130px]'>

                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>$15</div>/per month
                                </div>
                            </div>
                            <a  href="/" className=' bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited emails
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    1 User Account
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    RPM calculator
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Gmail, Outlook, SMTP
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    1 Email Template
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Customer Support
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Pro
                            </div>
                            <div className='flex md:h-[130px]'>
                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>$35</div>/per month
                                </div>
                            </div>
                            <a  href="/" className=' bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Everything in Starter, plus:
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited user accounts
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Unlimited Emails
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Load Search Filters
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Google Maps
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Multiple email address
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    DAT, Truckstop, DAT One, Truckersedge
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-4 items-center bg-white drop-shadow p-4 rounded'>
                            <div className='text-[18px] md:text-[21px] font-semibold'>
                                Enterprice
                            </div>
                            <div className='flex items-center flex-col '>
                                <div className='flex items-center gap-3'>
                                    <div className='text-[21px] md:text-[36px] text-black font-semibold'>${12.5*Amount}</div>/per month
                                </div>
                                <Slider onChange={(e:number)=>setAmount(e)} min={4} max={20} className='w-[120px]'/>
                                <div>
                                    User Account:{Amount}
                                </div>
                            </div>
                            <a  href="/" className='bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                Get started
                            </a>
                            <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    Everything in Pro, plus:
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>From 4 to Unlimited Users</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Multiple email addresses</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Google Maps with Tolls &amp; Load Info</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Multiple Sending Addresses</span>
                                </li>
                                <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                    <span>Email Signature</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
