import React from 'react';
import {Header} from "./componennts/Header/Header";
import { NavLink,Route,Routes } from "react-router-dom"
import {Template} from "./componennts/Template/Template";
import {Emails} from "./componennts/Emails/Emails";
import {Loads} from "./componennts/Loads/Loads";
import {Billing} from "./componennts/Billing/Billing";
import {ItemTemplate} from "./componennts/ItemTemplate/ItemTemplate";
import {Sign} from "./componennts/Sign/Sign";
import {Extension} from "./componennts/Extension/Extension";
import UserAuthWrapper from "./componennts/UserAuthWrapper/UserAuthWrapper";
function App() {
  return (
    <div className="bg-[#f2f2f2] w-screen min-h-screen">
      <Header/>
      <div className='flex p-16'>
          <div className='bg-white drop-shadow max-h-[350px] w-[325px] p-6 flex flex-col text-[21px] text-[#555] gap-3 font-bold'>
              <NavLink id={'link'} to={'/template/template'} className='hover:text-indigo-400 px-3 py-1 w-[180px]'>
                  My Template
              </NavLink>
              <NavLink id={'link'} to={'/template/emails'} className='hover:text-indigo-400 px-3 py-1 w-[180px]'>
                  My Emails
              </NavLink>
              <NavLink id={'link'} to={'/template/loads'} className='hover:text-indigo-400 px-3 py-1 w-[180px]'>
                  My Loads
              </NavLink>
              <NavLink id={'link'} to={'/template/billing'} className='hover:text-indigo-400 px-3 py-1 w-[180px]'>
                  Billing
              </NavLink>
          </div>
          <main>
              <Routes>
                  <Route path={'/template/template'} element={<UserAuthWrapper><Template/></UserAuthWrapper>}/>
                  <Route path='/template/create-template' element={<UserAuthWrapper><ItemTemplate/></UserAuthWrapper>}/>
                  <Route path='/template/template/:id' element={<UserAuthWrapper><ItemTemplate/></UserAuthWrapper>}/>
                  <Route path={'/template/emails'} element={<UserAuthWrapper><Emails/></UserAuthWrapper>}/>
                  <Route path={'/template/loads'}  element={<UserAuthWrapper><Loads/></UserAuthWrapper>}/>
                  <Route path={'/template/billing'} element={<Billing/>}/>
                  <Route path={'/template/sign'} element={<Sign/>}/>
                  <Route path={'/template/'} element={<UserAuthWrapper><div></div></UserAuthWrapper>}/>
                  <Route path={'/template/extension/:email'} element={<Extension/>}/>
              </Routes>
          </main>
      </div>
    </div>
  );
}

export default App;
