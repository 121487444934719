import React, {useEffect, useState} from 'react'
import {TableEmails} from "../TableEmails/TableEmails";
import {RootState, useAppDispatch} from "../../app/store";
import {message} from "antd";
import { useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {SetUserData} from "../../app/Auth";
import {useSelector} from "react-redux";
import {CreateEmails, GetEmails} from "../../app/Emails";


export const Emails = () => {
    const dispatch = useAppDispatch()
    const {email} = useSelector((u:RootState)=>u.Auth)
    const { emails} = useSelector((state:RootState)=>state.Emails)
    const [messageApi] = message.useMessage()
    const [ user, setUser ] = useState<any>([]);
    const [ , setProfile ] = useState<any>([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse:any) => setUser(codeResponse),
        onError: (error) => console.error('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }}).then((res:any) => {
                            messageApi.success("Success")
                            setProfile(res.data);
                            dispatch(SetUserData(res.data))
                            dispatch(CreateEmails({status_name:user.verified_email,name:res.data.name,email:res.data.email,service_name:'Google',mainEmail:email}))
                        }).catch((err:any) => {
                            console.log(err)
                            messageApi.error("Error")
                        });
            }
        },
        [ user,dispatch,email,messageApi ]
    );
    useEffect(() => {
        dispatch(GetEmails(email))
    }, []);

    return (
        <div className='m-10 flex flex-col gap-5 text-[#444]'>

            <div className='flex flex-col gap-2 text-[#444]'>
                <div className='font-bold text-[23px]'>
                    Emails
                </div>
                <div className='max-w-[870px] '>
                    Within this section, you can establish connections for the email addresses you intend to utilize for sending emails.
                </div>
                <button onClick={()=>login()} className=' flex justify-center items-center bg-indigo-500 hover:bg-sky-400  p-1 text-white font-semibold rounded max-w-[260px]'>
                    Add Email
                </button>
            </div>
            <TableEmails data={emails}/>
        </div>
    )
}
