import React, {useEffect} from 'react'
import { Table} from "antd";
import {ColumnsType, } from "antd/es/table";
import {RootState, useAppDispatch} from "../../app/store";
import {DeleteTemplate, EditTemplate, GetAllTemplates} from "../../app/Template";
import {useSelector} from "react-redux";
interface DataType{
    id:number,
    name:string,
    subject:string,
    action:any
}
export const TableTemplate = () => {
    const dispatch = useAppDispatch()
    const {email} = useSelector((state:RootState) => state.Auth)
    const {templates}= useSelector((state:RootState)=>state.Template)
    useEffect(() => {
        dispatch(GetAllTemplates(email))
    }, []);
    const columns :ColumnsType<DataType> = [
        {
            title:'Id',
            dataIndex:'id',
            key:'id',
            width:'15%',
        },
        {
            title:'Name',
            dataIndex:'name',
            key:'name',
            width:'30%',
        },
        {
            title:'Subject',
            dataIndex:'subject',
            key:'subject',
            width:'40%',
        },
        {
            title:'Actions',
            key:'actions',
            width:'15%',
            render: (_,record)=> <>
                <div onClick={()=>dispatch(EditTemplate(record.id))} className='text-sky-600 hover:text-sky-900 cursor-pointer'>
                    Edit
                </div>
                <div onClick={()=>dispatch(DeleteTemplate(record.id))} className='text-red-600 hover:text-red-900 cursor-pointer'>
                    Delete
                </div>
            </>
        },
    ]
    return (
        <>
            <Table columns={columns} dataSource={templates}/>
        </>
    )
}
