import axios from "axios";

const base = axios.create({
    baseURL:"https://master-dispatch.com/api"
})

export class TemplateAPI {
    async Get (id:string){
        const res = await base.get(`/templates/user/user_email=${id}`).then(u =>u.data)
        return res
    }
    async Edit(id:number){
        const res = await base.put(`/template${id}`).then(u=>u.data)
        return res
    }
    async Delete(id:number){
        const res = await base.delete(`/template${id}`).then(u=>u.data)
        return res
    }
    async Create(data:any){
        try {
            const res = await base.post("/template",data).then(u => u.data)
            return res
        }catch (e:any){
            console.error(e)
        }
    }
}