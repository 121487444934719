import {createAction, createAsyncThunk, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {LoadsAPi} from "../API/LoadsAPi";

interface InitialStateType {
    SavedLoads:Array<any>,
    OpenedLoads:Array<any>
}
const InitialState:InitialStateType={
    OpenedLoads:[],
    SavedLoads:[]
}

export const GetOpenedLoads = createAsyncThunk<unknown,string>("GET-OPENED-LOADS",async (email,{dispatch})=>{
    const response = await new LoadsAPi().GetOpened(email)
    dispatch(SetOpened(response))
})
export const GetSavedLoads = createAsyncThunk<unknown,string>("GET-SAVED-LOADS",async (email,{dispatch})=>{
    const response = await new LoadsAPi().GetSaved(email)
    dispatch(SetSeved(response))
})
const SetSeved = createAction<Array<any>>("SET-SEVED")
const SetOpened = createAction<Array<any>>("SET-OPENED")
export const Loads = createReducer(InitialState,{
    [SetSeved.type]:(state,action:PayloadAction<Array<any>>)=>{
        return{
            ...state,
            SavedLoads:action.payload
        }
    },
    [SetOpened.type]:(state, action:PayloadAction<Array<any>>) =>{
        return{
            ...state,
            OpenedLoads:action.payload
        }
    }
})