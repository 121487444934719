import React from 'react'
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
interface DataType{
    count:number,
    postedDate:string,
    origin:string,
    destiontion:string,
    stops:string,
    company:string,
    weight:string,
    rate:string,
    contacts:string
}
export const TableLoads:React.FC<{data:Array<any>}> = ({data}) => {
    const columns:ColumnsType<DataType> =[
        {
            title:'No',
            dataIndex:'No',
            key:'No',
            width:'10%',
        },
        {
            title:'Posted Date',
            dataIndex:'Posted Date',
            key:'Posted Date',
            width:'15%',
        },
        {
            title:'Origin',
            dataIndex:'origin',
            key:'origin',
            width:'10%',
        },
        {
            title:'Destiontion',
            dataIndex:'destiontion',
            key:'destiontion',
            width:'10%',
        },
        {
            title:'Stops',
            dataIndex:'stops',
            key:'stops',
            width:'10%',
        },
        {
            title:'Company',
            dataIndex:'company',
            key:'company',
            width:'10%',
        },
        {
            title:'Weight',
            dataIndex:'weight',
            key:'weight',
            width:'10%',
        },
        {
            title:'Rate',
            dataIndex:'rate',
            key:'rate',
            width:'10%',
        },
        {
            title:'contacts',
            dataIndex:'contacts',
            key:'contacts',
            width:'10%',
        },
    ]
    return (
        <>
            <Table className='w-[920px]' dataSource={data} columns={columns}/>
        </>
    )
}
