import {createAction, createAsyncThunk, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TemplateAPI} from "../API/TemplateAPI";


interface InitialStateType {
    templates:Array<any>
}
interface Template{
    subject: string,
    name: string,
    template: string
    ,email: string
}

const InitialState:InitialStateType = {
    templates:[]
}


export const CreateTemplate = createAsyncThunk<unknown,{subject: string,name: string,template: any,email: string}>("CREATE-TEMPLATE",async (value,{dispatch})=>{
     await new TemplateAPI().Create(value)
    dispatch(GetAllTemplates(value.email))
})
export const DeleteTemplate = createAsyncThunk<unknown,number>("DELETE-TEMPLATE",async (id,)=>{
     await new TemplateAPI().Delete(id)
})

export const GetAllTemplates = createAsyncThunk<unknown,string>("GET-ALL-TEMPLATES",async (value,{dispatch})=>{
    const res = await new TemplateAPI().Get(value)
    dispatch(SetTemplates(res))
})
export const EditTemplate = createAsyncThunk<unknown,number>("EDIT-TEMPLATE",async (id,)=>{
     await new TemplateAPI().Edit(id)
})
const SetTemplates = createAction<Array<Template>>("SET-TEMPLATES")
export const Template = createReducer(InitialState,{
    [SetTemplates.type]:(state,action:PayloadAction<Array<Template>>)=>{
        return{
            ...state,
            templates:action.payload
        }
    }
})